.Home .headline-lg {
    font-size: 128px;
    line-height: 108px;
}

.Home .subheadline-lg {
    font-size: 128px;
    line-height: 108px;
}

.Home .join-tag {
    font-size: 45px;
    font-weight: 700;
}

.Home .headline-helpers,
.Home .graph-helpers {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-self: center;
}

.Home h1.how-it-works {
    margin-bottom: 25px;
}

.Home .notes h4 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    overflow: hidden;
    line-height: 1.5;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.Home .notes p {
    color: #666;
}

.Home .lander {
    margin-top: 50px;
}

.Home .numberCircle {
    border-radius: 50%;
    width: 36px;
    height: 36px;
    padding: 2px;
    background: #BDE1ED;
    border: 3px solid #78BFDE;
    color: #78BFDE;
    font-weight: bold;
    text-align: center;
    display: block;
    margin: 0 auto;
}

.Home .wave {
    animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
    animation-duration: 2.5s;        /* Change to speed up or slow down */
    animation-iteration-count: infinite;  /* Never stop waving :) */
    transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
    display: inline-block;
}
  
@keyframes wave-animation {
    0% { transform: rotate( 0.0deg) }
    10% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
    20% { transform: rotate(-8.0deg) }
    30% { transform: rotate(14.0deg) }
    40% { transform: rotate(-4.0deg) }
    50% { transform: rotate(10.0deg) }
    60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
    100% { transform: rotate( 0.0deg) }
}

.htmlCodeCheckMark {
    color: #28a745;
    font-size: 20px;
}